import React from "react";

export const Header = () => {
  return (
    <header>
	<div id="home" class="overlay">
        <h1>Simply The Best</h1>
        <h3>We finally made it!</h3>
        <p>Our 45 years of experience, finally at the reach of your hand!</p>
	{/* <button>READ MORE</button> */}
		</div>
</header>
  );
};