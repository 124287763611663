import React from "react";
import mainLogo from "../assets/logo.png";

export const Nav = () => {
  return (
    <div className="header" id="myHeader">
        <img className="logo" src={mainLogo} alt="Prettan main logo" />
      <nav>
        <ul>
          <a href="#home"><li className="hover-underline-animation"> Home</li></a>
          <a href="#products"><li className="hover-underline-animation"> Products</li></a>
          {/* <a href="#suscription"><li className="hover-underline-animation"> Suscription </li></a>
          <a href="#Contact"><li className="hover-underline-animation">  Contact</li></a> */}
        </ul>
      </nav>
    </div>
  );
};