import React from "react";

export const CardProducts = ({
  nameProduct,
  photo,
  shortDescription,
  howToUse,
  notes,
  linkFicha,
  ficha,
  enlaceAmazon,
  ingredients,
  othersIngredients,
}) => {
  return (
    <div className="card-container">
      <div className="product">
          <img className="image-product" src={photo} loading="lazy" alt="imagen producto" />
          <div className="ingredients">
                <div classname="contenido">
                    <h2>{nameProduct}</h2>
                    <h4>Active ingredients</h4>
                    <ol>
                        {ingredients.map((ingrediente) => <li>{ingrediente.ingredientes}</li>)}
                    </ol>
                    <h4>Ingredients</h4>
                    <ol>
                        {othersIngredients.map((ingrediente) => {return <li>{ingrediente.otherIngredientes}</li>})}
                    </ol>
                    <h4>Insructions</h4>
                    <p>{howToUse}</p>
                    <h4>Notes</h4>
                    <p>{notes}</p>
                    <div className="btnsecc">
                        <a href={linkFicha} download={ficha}><button className="css-button-gradient--7">Data Sheet </button></a>
                        <a href={enlaceAmazon} target="_blank" rel="noreferrer"><button className="css-button-gradient--7">Buy now!</button></a>
                    </div>
                </div>
          </div>
      </div>
    </div>
  );
};